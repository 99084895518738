<template>
  <div>
    <vue-context ref="testMenu">
      <li>
        <a href="#" @click.prevent="removeExam">Remove Exam</a>
      </li>
      <li>
        <a href="#" @click.prevent="mostlyCompleteExamIncorrectly"
          >Mostly Complete Exam Incorrectly</a
        >
      </li>
      <li>
        <a href="#" @click.prevent="mostlyCompleteExamCorrectly"
          >Mostly Complete Exam Correctly</a
        >
      </li>
    </vue-context>
    <va-card title="Edit User">

      <template slot="actions">
        <va-button
          :icon="this.hiddenView ? 'fa fa-eye' : 'fa fa-eye-slash'"
          color="#5353ec"
          :title="this.hiddenView ? 'Show User' : 'Hide User'"
          value="Show User"
          @click="toggleView()"
        />
        <va-button
          v-show="!this.emailIsVerified"
          icon="fa fa-envelope-o"
          color="primary"
          title="Resend Verification Email"
          @click="resendVerificationEmail()"
        />
        <va-button
          icon="fa fa-refresh"
          @click="
            getUser();
            getExams();
            refreshTables();
          "
        />
        <va-button
          icon="fa fa-envelope-o"
          color="#9231EC"
          title="Send Download App Instructions"
          @click="sendDownloadAppInstructions()"
        />
        <va-button
          icon="
          fa
          fa-user-times"
          color="danger"
          @click="deleteUser"
        />
      </template>

      <va-notification :color="this.SubscriptionAlertColor">
        <va-badge :color="this.SubscriptionAlertColor">
          {{ this.SubscriptionAlertTitle }}
        </va-badge>
        {{ this.SubscriptionAlertText }}
      </va-notification>

      <va-notification
        color="danger"
        class="mt-2"
        v-if="this.defaultIsDeactivated"
      >
        <va-badge color="danger"> Deactivated </va-badge>
        This account has been deactivated, the user will not be able to login or
        access the app.
      </va-notification>

      <va-modal
        v-model="showMediumModal"
        hide-default-actions
        class="custom-qa-modal flex xs12"
        title="Exam Answers"
        size="large"
        ok-text="Ok"
      >
        <template>
          <va-select
            :label="'Model'"
            v-model="currentModel"
            :options="modelList"
            text-by="name"
            key-by="value"
            :no-clear="true"
          />
        </template>

        <div class="modal-container" ref="scrollable">
          <va-data-table
            :fields="ExamFields_"
            :data="ExamsFilteredData_"
            no-pagination
          />
        </div>
        <div class="pt-2">
          <va-button
            slot="actions"
            @click="showMediumModal = false"
            color="primary"
            class="mr-2"
          >
            Close
          </va-button>
        </div>
      </va-modal>

      <div class="row">
        <va-card title="User Information" class="flex lg8 xs12">
          <AddExamModal 
          :active="addExamModalStatus" 
          :options="selectedOptionsForExams" 
          @save="saveExamChanges"
          @close="closeModal" 
          />
          <form @submit.prevent="onsubmit">
            <div class="row">
              <div class="flex xs12 sm12 lg2 imgContainer">
                <div class="imgContainer">
                  <img
                    class="justify—center flex bigAvatar"
                    :src="photoURL"
                    :alt="name"
                  />
                </div>
              </div>
              <div class="flex xs12 sm12 lg10">
                <va-input
                  label="Email"
                  v-model="email"
                  placeholder="User Email"
                >
                  <va-icon
                    slot="prepend"
                    color="gray"
                    name="fa fa-envelope-o"
                  />
                </va-input>
                <div>
                  <!-- <div
                    v-if="provider !== 'password'"
                  >
                    {{ provider }}
                  </div> -->
                  <div v-if="provider == 'password'">
                    <va-button
                      @click="resetPassword"
                      :icon="
                        resettingPassword
                          ? 'fa fa-spinner fa-spin'
                          : 'fa fa-key'
                      "
                    >
                      Reset Password
                    </va-button>
                  </div>
                </div>

                <va-input label="Name" v-model="name" placeholder="Full Name">
                  <va-icon slot="prepend" color="gray" name="fa fa-id-card-o" />
                </va-input>
                <va-input label="Phone" v-model="phoneNumber" @input="maskPhone" maxlength="12" placeholder="Phone Number">
                  <va-icon slot="prepend" color="gray" name="fa fa-phone" />
                </va-input>
                <va-select
                  :label="'Credential'"
                  text-by="displayName"
                  key-by="value"
                  v-model="credential"
                  :options="credentials"
                  :no-clear="true"
                />
                <va-select
                  :label="'Country'"
                  v-model="country"
                  :options="countryOptions"
                  @input="onCountryChange"
                  text-by="name"
                  key-by="name"
                  :no-clear="true"
                />
                <va-select
                  v-if="stateIsListed"
                  class="custom-dropdown-size"
                  :label="'State'"
                  v-model="state"
                  :options="stateOptions"
                  text-by="name"
                  key-by="abbreviation"
                  @input="onStateChange"
                  :disabled="!canShowField"
                  :no-clear="true"
                />
                <va-input
                  v-else
                  :label="'State'"
                  v-model="state"
                  type="text"
                  placeholder="i.e Louisiana"
                />
                <va-input
                  :label="'City'"
                  class="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 focus:outline-none focus:bg-white focus:border-gray-500"
                  v-model="city"
                  type="text"
                  placeholder="i.e New York"
                />
              </div>
            </div>

            <div v-if="!hiddenView" class="flex text-center">
              <div class="row toggleBody">
                <va-toggle v-model="developer" class="hideBottomMargin"/>
                <span>Developer</span>
              </div>
              <div class="row toggleBody">
                <va-toggle v-model="freeSubscription" class="hideBottomMargin"/>
                <span>Free Subscription</span>
              </div>
              <div class="row toggleBody">
                <va-toggle v-model="emailIsVerified" class="hideBottomMargin"/>
                <span>Verified Email</span>
              </div>
              <div class="row toggleBody">
                <va-toggle v-model="demoAccess" class="hideBottomMargin"/>
                <span>Demo Access</span>
              </div>
              <div class="row toggleBody">
                <va-toggle v-model="isDeactivated" class="hideBottomMargin"/>
                <span>Account Deactivated</span>
              </div>


              <div class="row">
                <va-input
                  v-model="formatedCreationDate"
                  placeholder="No Creation Date"
                  label="Creation Date"
                  :disabled="true"
                />
              </div>

              <div class="row">
                <va-input
                  v-model="lastLogin"
                  placeholder="Never logged in"
                  label="Last Login (from app)"
                  :disabled="true"
                />
              </div>
              <div v-if="courseAccess">
                <va-date-picker
                  :config="{ dateFormat: 'm-d-Y' }"
                  v-model="examAccess"
                  label="Pick dates"
                />
              </div>
              <div class="flex flex-center">
                <div class="flex md6 sm8 xs12">
                  <va-button @click="updateUser" v-if="!loading">
                    <i class="va-icon fa fa-user" style="margin-right: 1em" />
                    Update User
                  </va-button>
                  <va-button @click="cloneUser" v-if="!loading">
                    <i class="va-icon fa fa-copy" style="margin-right: 1em" />
                    Clone User (Developer)
                  </va-button>
                  <va-button v-else>
                    <semipolar-spinner :color="'white'" />
                  </va-button>
                </div>
              </div>
            </div>
          </form>
          <div class="row" v-if="hiddenView">
            <div class="flex xs12 lg12">
              <ExamHistory
                :uid="this.$route.params.uid"

                :loading="loading"
                :testsLoading="testsLoading"
                :certLoading="certLoading"
                :emailing="emailing"
                :showPassedOnly="showPassedOnly"
                :exams="exams"
                :fields="fields"
                @contextAction="contextAction"
                
                @showScoreData="showScoreData"
                @printCert="printCert"
                @emailCert="emailCert"
                @redirectEvaluation="redirectEvaluation"
                @addExam="addExam"
              />
            </div>
            <div class="flex xs12 lg4">
              <va-card v-if="!hiddenView" title="Completed Practice">
                <template slot="actions">
                  <!-- <va-button
                icon="fa fa-clock-o"
                color="success"
                @click="showfTimeStats()"
              /> -->
                </template>
                <va-data-table
                  v-if="timeStatsVisible"
                  :fields="timeFields"
                  :data="timestats"
                  :loading="timeStatsLoading"
                />
                <va-data-table
                  :fields="trainingFields"
                  :data="training"
                  :loading="loading"
                  hoverable
                  clickable
                  @row-clicked="removeTraining"
                />
                <va-button
                  :disabled="training.length === 0"
                  small
                  outline
                  color="danger"
                  icon="ion-md-close ion"
                  @click="resetTraining"
                >
                  Reset Practice
                </va-button>
                <va-button
                  :disabled="loading"
                  small
                  outline
                  color="info"
                  icon="fa fa-plus-circle"
                  @click="addTraining"
                >
                  <span v-if="!loading">Add Practice</span>
                  <span v-else>Loading...</span>
                </va-button>
              </va-card>
            </div>
          </div>
        </va-card>

        <div class="flex lg4 xs12">
          <div class="row">
            <va-card class="flex xs12" title="Training History">
              <template slot="actions">
                <va-checkbox
                  :label="'Not Viewed Only'"
                  v-model="showViewedOnly"
                />
              </template>
              <va-data-table
                v-if="renderComponent"
                :fields="TrainingStatusFields"
                :data="filteredTrainingHistory"
                :loading="loading"
                :per-page="10"
                ref="TrainingStatusTable"
                hoverable
              />
            </va-card>
            <!-- Related Organizations  -->
            <va-card class="flex xs12" title="Organizations" v-if="!hiddenView">
              <va-data-table
                :fields="orgFields"
                :data="organizations"
                :loading="loading"
                @row-clicked="
                  (row) => {
                    $router.push({ name: 'edit', params: { uid: row.id } });
                  }
                "
                hoverable
                clickable
              />
            </va-card>
            <!-- Administered Organizations  -->
            <va-card
              v-if="orgsAdministered.length > 0"
              class="flex xs12"
              title="Org Administration"
            >
              <va-data-table
                :fields="orgAdminFields"
                :data="orgsAdministered"
                :loading="loading"
                @row-clicked="
                  (row) => {
                    $router.push({ name: 'edit', params: { uid: row.id } });
                  }
                "
                hoverable
                clickable
              />
            </va-card>
          </div>
        </div>
      </div>

      <div class="row" v-if="!hiddenView">
        <div class="flex xs12 lg8 mb-10">
          <NihssTickets :parent_uid="this.$route.params.uid" />
        </div>
      </div>

      <div class="row" v-if="!hiddenView">
        <div class="flex xs12 lg8">
          <ExamHistory
            :uid="this.$route.params.uid"

            :loading="loading"
            :testsLoading="testsLoading"
            :certLoading="certLoading"
            :emailing="emailing"
            :showPassedOnly="showPassedOnly"
            :exams="exams"
            :fields="fields"
            @contextAction="contextAction"
            
            @showScoreData="showScoreData"
            @printCert="printCert"
            @emailCert="emailCert"
            @redirectEvaluation="redirectEvaluation"
            @addExam="addExam"
          />
        </div>
        <div class="flex xs12 lg4">
          <va-card v-if="!hiddenView" title="Completed Practice">
            <template slot="actions">
              <!-- <va-button
                icon="fa fa-clock-o"
                color="success"
                @click="showfTimeStats()"
              /> -->
            </template>
            <va-data-table
              v-if="timeStatsVisible"
              :fields="timeFields"
              :data="timestats"
              :loading="timeStatsLoading"
            />
            <va-data-table
              :fields="trainingFields"
              :data="training"
              :loading="loading"
              hoverable
              clickable
              @row-clicked="removeTraining"
            />
            <va-button
              :disabled="training.length === 0"
              small
              outline
              color="danger"
              icon="ion-md-close ion"
              @click="resetTraining"
            >
              Reset Practice
            </va-button>
            <va-button
              :disabled="loading"
              small
              outline
              color="info"
              icon="fa fa-plus-circle"
              @click="addTraining"
            >
              <span v-if="!loading">Add Practice</span>
              <span v-else>Loading...</span>
            </va-button>
          </va-card>
        </div>
      </div>
    </va-card>
  </div>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";
import firebase from "firebase";
import moment from "moment";
import getCredentials from "../../../data/credentials";
import NihssTickets from "../../tickets/nihss-tickets.vue";
import AddExamModal from "./AddExamModal.vue";
import axios from "@/scripts/interceptor.js";
import ExamHistory from "../../exam-history/examHistory.vue";
/**
 * @TODO In the future transform this into a collection from Firestore.
 */
export default {
  name: "EditUser",
  data() {
    return {
      showMediumModal: false,
      phoneNumber: "",
      currentModel: false,
      englishSubs: [],
      modelList: [
        {
          name: "None Selected",
          value: "none",
        },
      ],
      error: {
        active: false,
        message: "",
      },
      emailing: false,
      examAnswers: [],
      addExamModalStatus: false,
      selectedOptionsForExams: [],  
      email: "",
      cityIsListed: true,
      city: "",
      cityOptions: [],
      countryOptions: [],
      cityFormatedOptions: [],
      CourseNames: [],
      renderComponent: true,
      resettingPassword: false,
      testsLoading: true,
      timeStatsVisible: false,
      timeStatsLoading: false,
      loading: false,
      certLoading: false,
      photoURL: "https://nihss3.com/assets/defaultProfilePic.png",
      timestats: [],
      showPassedOnly: false,
      showViewedOnly: false,
      password: "test123",
      provider: null,
      name: "",
      courseAccess: false,
      examAccess: "",
      uid: "",
      lastLogin: "",
      developer: false,
      freeSubscription: false,
      verifiedEmail: false,
      isDeactivated: false,
      demoAccess: false,
      defaultEmailIsVerified: undefined,
      defaultDeveloper: undefined,
      defaultFreeSubscription: undefined,
      defaultDemoAccess: undefined,
      defaultIsDeactivated: undefined,
      emailErrors: [],
      exams: [],
      training: [],
      creationDate: 0,
      formatedCreationDate: "",
      organizations: [],
      trainingstatus: [],
      orgsAdministered: [],
      rightClickData: {},
      credential: "",
      credentials: [],
      country: "",
      state: "",
      states: [],
      stateOptions: [],
      stateIsListed: true,
      cityOptions: [],
      emailIsVerified: false,
      SubscriptionAlertTitle: "Subscription",
      SubscriptionAlertColor: "warning",
      SubscriptionAlertText: "Subscription status loading...",
      hiddenView: false,
      perPageOptions: [10, 25, 50, 100],
      perPage: 10,
    };
  },
  components: {
    SemipolarSpinner,
    NihssTickets,
    AddExamModal,
    ExamHistory,
  },
  async created() {
    await this.getCountryOptions();
    await this.getCityOptions();
    await this.getStateOptions();
    await this.getUser();
    await this.getExams();

  },
  async mounted() {
    this.credentials = await getCredentials();
  },
  computed: {
    ExamFields_() {
      return [
        {
          name: "QuestionText",
          title: "Question Text",
          sortField: "QuestionText",
          width: "20%",
        },
        {
          name: "selectedAnswer",
          title: "Selected Answer",
          sortField: "selectedAnswer",
          width: "40%",
        },
        {
          name: "correctAnswer",
          title: "Correct Answer",
          sortField: "correctAnswer",
          width: "40%",
        },
        {
          name: "correct",
          title: "Correct",
          sortField: "correct",
          width: "5%",
        },
      ];
    },
    canShowField() {
      if (this.country !== null) {
        if (
          this.country == "United States" ||
          this.country?.name == "United States"
        ) {
          return true;
        }
      }
      return false;
    },
    ExamsFilteredData_() {
      const word = this.currentModel.value;
      if (!word || word == "none") {
        return this.examAnswers;
      }
      return this.examAnswers.filter((exam) => {
        // return the ones that have model in the name
        return exam.model == word;
      });
    },
    filteredExams() {
      if (!this.showPassedOnly) {
        return this.exams;
      } else {
        return this.exams.filter((exam) => {
          return exam.Passed;
        });
      }
    },
    filteredTrainingHistory() {
      if (!this.showViewedOnly) {
        return this.trainingstatus;
      } else {
        // return just the ones that have timeSpent = "00:00" or "False"
        return this.trainingstatus.filter((training) => {
          return training.timeSpent == "00:00" || training.timeSpent == "False";
        });
      }
    },
    timeFields() {
      return [
        {
          name: "course.Name",
          title: "Course Name",
        },
        {
          name: "secondsIn",
          title: "Seconds In",
        },
      ];
    },
    fields() {
      return [
        {
          name: "examName",
          title: "Exam Name",
          sortField: "examName",
        },
        {
          name: "__slot:testCompleted",
          title: "Completion Date",
          sortField: "TestCompleted",
        },
        {
          name: "__slot:score",
          title: "Score",
          sortField: "score",
        },
        {
          name: "__slot:passed",
          title: "Actions",
          sortField: "passed",
        },
      ];
    },
    trainingFields() {
      return [
        {
          name: "Name",
          title: "Practice Title",
        },
      ];
    },
    orgFields() {
      return [
        {
          name: "name",
          title: "Organization",
        },
      ];
    },
    TrainingStatusFields() {
      return [
        {
          name: "courseName",
          sortField: "courseName",
          title: "Page Name",
        },
        {
          name: "timeSpent",
          sortField: "timeSpent",
          title: "Time Spent",
        },
      ];
    },
    orgAdminFields() {
      return [
        {
          name: "name",
          title: "Organization",
        },
      ];
    },
  },
  methods: {
    contextAction(e, props) {
      this.$refs.testMenu.open(e);
      this.rightClickData = props.rowData;
    },
    maskPhone() {
      this.phoneNumber = this.phoneNumber.replace(/[^0-9]/g, '');
      if (this.phoneNumber.length > 3 && this.phoneNumber.length <= 6) {
        this.phoneNumber = `${this.phoneNumber.slice(0, 3)}-${this.phoneNumber.slice(3)}`;
      } else if (this.phoneNumber.length > 6) {
        this.phoneNumber = `${this.phoneNumber.slice(0, 3)}-${this.phoneNumber.slice(3, 6)}-${this.phoneNumber.slice(6)}`;
      }
    },
    async cloneUser() {
      this.$swal
        .fire({
          title: "Clone User",
          text: "Are you sure you want to clone this user?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, clone it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // open swal with loading
            const WaitingSwal = this.$swal.fire({
              title: "Loading...",
              allowOutsideClick: false,
              // hide all buttons
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: false,
              onBeforeOpen: async () => {
                try {
                  this.$swal.showLoading();
                  // send email
                  const response = await axios.post(
                    "api/admin/users/replicate",
                    { uid: this.$route.params.uid }
                  );
                  this.$swal.fire({
                    type: "success",
                    title: "User Cloned!",
                    text: "The user has been cloned successfully.",
                    allowOutsideClick: false,
                    html: `
                  <p>Your new account's information:</p><br/>
                  <p>Uid: ${response.data.data.uid} </p><br/>
                  <p>Email: <b>${response.data.data.email}</b> </p> 
                  <p>Password: <b>${response.data.data.password}</b> </p> 
                `,
                  });
                } catch (error) {
                  this.$swal.close();
                  this.$swal.fire(
                    "Error!",
                    "An error occurred while cloning the user.",
                    "error"
                  );
                }
              },
            });
          }
        });
    },
    async toggleView() {
      this.hiddenView = !this.hiddenView;
    },
    async getCountryOptions() {
      await this.$db
        .collection("countries")
        .orderBy("name")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.countryOptions.push(doc.data());
          });
        });
      //move option united states to the top of the array
      let unitedStates = this.countryOptions.find(
        (country) => country.name == "United States"
      );
      if (unitedStates) {
        this.countryOptions = this.countryOptions.filter(
          (country) => country.name != "United States"
        );
        this.countryOptions.unshift(unitedStates);
      }

    },
    async getCityOptions() {
      await firebase
        .firestore()
        .collection("cities")
        .orderBy("city")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            //foreach key in doc.data().city create new object with {name: key, value: key}
            let options = [];
            for (const value of doc.data().city) {
              options.push({
                name: value,
                value: value,
              });
            }
            this.cityOptions.push({
              options: options,
              value: doc.id.toLowerCase(),
            });
          });
        });
    },
    async getStateOptions() {
      this.stateOptions = [];
      await firebase
        .firestore()
        .collection("states")
        .orderBy("name")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.stateOptions.push(doc.data());
          });
        });
    },
    onStateChange() {
      this.city = "";
      //reset city listed
      this.cityIsListed = true;
      if (this.stateOptions && this.cityOptions) {
        //check what state has this code
        let state = this.stateOptions.find(
          (state) =>
            state.abbreviation == this.state?.abbreviation ||
            state.abbreviation == this.state
        );
        let cityOptions = this.cityOptions.find(
          (city) => city.value == state?.name.toLowerCase()
        );
        this.cityFormatedOptions = cityOptions?.options
          ? cityOptions?.options
          : [];
        this.cityFormatedOptions.push({
          name: "Not Listed",
          value: "Not Listed",
        });
      }
    },
    async sendDownloadAppInstructions() {
      const userUID = this.$route.params.uid;
      this.$swal
        .fire({
          title: "Download Instructions",
          text: "Are you sure you want to send the download instructions email?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, send it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // open swal with loading
            const WaitingSwal = this.$swal.fire({
              title: "Loading...",
              allowOutsideClick: false,
              // hide all buttons
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: false,
              onBeforeOpen: async () => {
                this.$swal.showLoading();
                // find user in firestore and check if "resendColdown" is in the past
                const user = await firebase
                  .firestore()
                  .collection("users")
                  .doc(userUID);
                const userDoc = await user.get();
                const userDocData = userDoc.data();
                const userEmail = userDocData.email;
                const resendColdown = userDocData.resendColdown;
                const now = new Date();
                if (!resendColdown) {
                  // send email
                  await axios.post("api/users/mail/instructions/send", {
                    email: userEmail,
                  });
                  // set resendColdown
                  await user.update({
                    resendColdown: firebase.firestore.Timestamp.fromDate(
                      new Date(now.getTime() + 5 * 60000)
                    ),
                  });
                  this.$swal.fire(
                    "Email Sent!",
                    "The instructions has been sent to the user email address.",
                    "success"
                  );
                } else {
                  const resendColdownDate = new Date(
                    resendColdown.seconds * 1000
                  );
                  if (resendColdownDate > now) {
                    this.error.active = true;
                    this.error.message =
                      "You can only send emails once every 5 minutes.";
                    this.$swal.fire(
                      "Error!",
                      "You can only send emails once every 5 minutes.",
                      "error"
                    );
                    return;
                  } else {
                    await axios.post("api/users/mail/instructions/send", {
                      email: userEmail,
                    });
                    // set resendColdown
                    await user.update({
                      resendColdown: firebase.firestore.Timestamp.fromDate(
                        new Date(now.getTime() + 5 * 60000)
                      ),
                    });
                    this.$swal.fire(
                      "Email Sent!",
                      "The instructions has been sent to the user email address.",
                      "success"
                    );
                  }
                }
              },
            });
          }
        });
    },

    async resendVerificationEmail() {
      const userUID = this.$route.params.uid;
      this.$swal
        .fire({
          title: "Resend Email",
          text: "Are you sure you want to resend the verification email?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, resend it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            // open swal with loading
            const WaitingSwal = this.$swal.fire({
              title: "Loading...",
              allowOutsideClick: false,
              // hide all buttons
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: false,
              onBeforeOpen: async () => {
                this.$swal.showLoading();
                // find user in firestore and check if "resendColdown" is in the past
                const user = await firebase
                  .firestore()
                  .collection("users")
                  .doc(userUID);
                const userDoc = await user.get();
                const userDocData = userDoc.data();
                const userEmail = userDocData.email;
                const resendColdown = userDocData.resendColdown;
                const now = new Date();
                if (!resendColdown) {
                  // send email
                  await axios.post("api/users/mail/confirm/send", {
                    email: userEmail,
                  });
                  // set resendColdown
                  await user.update({
                    resendColdown: firebase.firestore.Timestamp.fromDate(
                      new Date(now.getTime() + 5 * 60000)
                    ),
                  });
                  this.$swal.fire(
                    "Email Sent!",
                    "A verification email has been sent to your email address.",
                    "success"
                  );
                } else {
                  const resendColdownDate = new Date(
                    resendColdown.seconds * 1000
                  );
                  if (resendColdownDate > now) {
                    this.error.active = true;
                    this.error.message =
                      "You can only send emails once every 5 minutes.";
                    this.$swal.fire(
                      "Error!",
                      "You can only send emails once every 5 minutes.",
                      "error"
                    );
                    return;
                  } else {
                    await axios.post("api/users/mail/confirm/send", {
                      email: userEmail,
                    });
                    // set resendColdown
                    await user.update({
                      resendColdown: firebase.firestore.Timestamp.fromDate(
                        new Date(now.getTime() + 5 * 60000)
                      ),
                    });
                    this.$swal.fire(
                      "Email Sent!",
                      "A verification email has been sent to your email address.",
                      "success"
                    );
                  }
                }
              },
            });
          }
        });
    },
    async showScoreData(id, answers) {
      // show swal with loading
      const WaitingSwal = this.$swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        // hide all buttons
        showConfirmButton: false,
        showCancelButton: false,
        showCloseButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });

      const localModelList = [];
      // await to get the models
      await this.getModels();
      // await to get iln8n
      await this.getEnglishSubs();
      // get test data from Test collection
      const finalExamAnswers = [];
      await firebase
        .firestore()
        .collection("Test")
        .doc(id)
        .get()
        .then((test) => {
          const data = test.data();
          // foreach item in data, get QuestionText and append to finalExamAnswers
          data.questions.forEach((item, id) => {
            // itinerate item.Answers to find the correct answer
            let correctAnswer = "";
            let indexCorrectAnswer = 0;
            let SelectedQuestionName = "";
            item.Answers.forEach((answer, idquest) => {
              // check if idquest has the same id that id
              if (idquest == answers[id]) {
                SelectedQuestionName = answer.AnswerText;
              }
              if (answer.Correct) {
                correctAnswer = answer.AnswerText;
                indexCorrectAnswer = item.Answers.indexOf(answer);
              }
            });
            // get value of selected answer from answers using current index
            // from item.QuestionText remove "ExamQuestion" and add te rest to item.Name
            const QuestionNumber = item.QuestionText.replace(
              "ExamQuestion",
              ""
            );

            const FinalName = `${QuestionNumber} - ${item.Name}`;

            finalExamAnswers.push({
              QuestionText: FinalName,
              model: item.Model.id,
              correct:
                correctAnswer == SelectedQuestionName
                  ? '<i class="fa fa-check" />'
                  : '<i class="fa fa-times" style="color: red;"/>',
              indexCorrectAnswer: indexCorrectAnswer,
              answers: item.Answers,

              selectedAnswer: this.englishSubs[SelectedQuestionName + "_abbrv"],
              selectedAnswerFull: this.englishSubs[SelectedQuestionName],

              correctAnswer: this.englishSubs[correctAnswer + "_abbrv"],
              correctAnswerFull: this.englishSubs[correctAnswer],
            });

            localModelList.push(item.Model.id);

            this.examAnswers = finalExamAnswers;
          });
        });

      // unique all items from localModelList using for of loop
      const uniqueModelList = [];
      for await (const item of localModelList) {
        if (!uniqueModelList.includes(item)) {
          uniqueModelList.push(item);
        }
      }

      this.modelList = this.modelList.filter((item) => {
        // check if is in uniqueModelList and if its different from none so add it to the list
        return uniqueModelList.includes(item.value) || item.value == "none";
      });

      // close WaitingSwal
      WaitingSwal.close();
      this.currentModel = {
        name: "None Selected",
        value: "none",
      };
      this.showMediumModal = true;
    },
    showTimeStats() {
      if (this.timeStatsVisible === true) {
        this.timeStatsVisible = false;
        return;
      }
      this.timeStatsVisible = true;
      this.timeStatsLoading = true;
      const trainingStats = [];
      firebase
        .firestore()
        .collection("users")
        .doc(this.$route.params.uid)
        .collection("TrainingStats")
        .get()
        .then((trainings) => {
          trainings.docs.forEach(async (training) => {
            const trainingData = training.data();

            trainingData.course = await trainingData.course
              .get()
              .then((snap) => {
                const data = snap.data();
                data.id = snap.id;
                return data;
              });
            const found = trainingStats.find((stat) => {
              return stat.course.id === trainingData.course.id;
            });
            if (!found) {
              trainingStats.push({
                course: trainingData.course,
                secondsIn: trainingData.secondsIn,
              });
            } else {
              found.secondsIn += trainingData.secondsIn;
            }
          });
          this.timeStatsLoading = false;
          this.timestats = trainingStats;
        });
    },
    getModels() {
      this.loading = true;
      const model = firebase.firestore().collection("model");
      model.get().then((result) => {
        this.loading = false;
        this.modelList = [];
        this.models = [];
        result.forEach((doc) => {
          const data = doc.data();
          if (data.Active) {
            this.modelList.push({
              name: `${data.patientName} (${data.name}) `,
              value: doc.id,
            });
          }
        });
      });
    },
    async getEnglishSubs() {
      this.loading = true;
      const il8n = firebase.firestore().collection("i18n").doc("English");
      await il8n.get().then((result) => {
        this.loading = false;
        this.englishSubs = result.data();
      });
    },
    onCountryChange() {
      if (
        this.country.name == "United States" ||
        this.country.name == "United States of America"
      ) {
        this.stateIsListed = true;
      } else {
        if (this.state.length < 2) {
          this.state = "";
        }
        this.stateIsListed = false;
      }
    },
    getUser() {
      this.loading = true;
      const retrieveUser = firebase
        .firestore()
        .collection("users")
        .doc(this.$route.params.uid);
      const uid = this.$route.params.uid;
      this.uid = uid;
      this.training = [];
      this.organizations = [];
      this.trainingstatus = [];
      this.orgsAdministered = [];
      this.emailing = false;

      retrieveUser.get().then((result) => {
        this.loading = false;
        const userData = result.data();
        let state = this.stateOptions.find((state) => {
          return state.abbreviation == userData.stateCode;
        });
        if (
          userData.countryCode == "United States" ||
          userData.countryCode == "United States of America"
        ) {
          this.stateIsListed = true;
        } else {
          this.stateIsListed = false;
        }
        this.provider = userData.provider;
        this.credential = userData.credentialCode
          ? userData.credentialCode
          : "NOT SET";
        this.country = userData.countryCode ? userData.countryCode : "NOT SET";
        //Avoid nesting ternary operators
        this.state = state || userData.stateCode;
        if(!this.stateIsListed && state?.name){
          this.state = state.name;
        }
        this.email = userData.email;
        this.firstEmail = userData.email;
        this.name = userData.displayName;
        this.phoneNumber = userData.phoneNumber;
        this.photoURL = userData.photoURL;
        this.lastLogin = userData.lastLogin
          ? moment(new Date(userData.lastLogin?.seconds * 1000)).format(
              "MMMM Do YYYY, h:mm:ss a"
            )
          : "";
        // this.courseAccess = userData.courseAccess;
        this.examAccess = moment.unix(userData.examAccess).format("MM-DD-yy");
        this.emailIsVerified = userData.emailIsVerified;
        this.isDeactivated = userData.isDeactivated === true;
        this.developer = userData.developer === true;
        this.freeSubscription = userData.freeSubscription === true;
        this.demoAccess = userData.demoAccess === true;

        this.defaultEmailIsVerified = userData.emailIsVerified;
        this.defaultDeveloper = userData.developer === true;
        this.defaultFreeSubscription = userData.freeSubscription === true;
        this.defaultDemoAccess = userData.demoAccess === true;
        this.defaultIsDeactivated = userData.isDeactivated === true;

        this.creationDate = userData.creationDate;
        this.formatedCreationDate = moment(
          new Date(userData.creationDate?.seconds * 1000)
        ).format("MMMM Do YYYY, h:mm:ss a");
        this.city = userData.cityCode ? userData.cityCode : "";

      

        if (this.stateOptions && this.cityOptions) {
          //find in this.cityOptions that is a array the key that has value = (state.name). toLowerCase()
          let cityOptions = this.cityOptions.find(
            (city) => city.value == state?.name.toLowerCase()
          );
          this.cityFormatedOptions = cityOptions?.options
            ? cityOptions?.options
            : [];
          //append "Not Listed" option
          this.cityFormatedOptions.push({
            name: "Not Listed",
            value: "Not Listed",
          });
        }

        if (userData.Trainings) {
          userData.Trainings.forEach((trainingId) => {
            firebase
              .firestore()
              .collection("course")
              .doc(trainingId)
              .get()
              .then((course) => {
                const data = course.data();
                this.training.push({ Name: data.Name, id: course.id });
              });
          });
        }
        // get the organizations subcollection data
        const Orgs = [];
        const query = firebase
          .firestore()
          .collection("users")
          .doc(this.$route.params.uid)
          .collection("Organizations");
        query.get().then((result) => {
          const orgPromises = result.docs.map((doc) => {
            const data = doc.data();
            const organizationReference = firebase
              .firestore()
              .collection("organizations")
              .doc(data.organization.id);
            return organizationReference.get().then((orgDoc) => {
              if (orgDoc.exists) {
                const orgData = orgDoc.data();
                Orgs.push({
                  id: orgDoc.id,
                  name: orgData.name,
                });
              }
            });
          });
          Promise.all(orgPromises).then(() => {
            this.organizations = Orgs;
          });
        });

        //get user bearer from firebase auth
        const user = firebase.auth().currentUser;
        const BeareToken = user.getIdToken();

        const subsStatus = axios.get(
          `api/users/subscribed/${this.$route.params.uid}`
        );

        subsStatus
          .then((result) => {
            let response = result.data?.data;
            switch (response?.status) {
              case "organization":
                this.SubscriptionAlertColor = "success";
                this.SubscriptionAlertTitle = "Organization Active";
                this.SubscriptionAlertText =
                  "The user is currently subscribed. End date: " +
                  response?.endDate;
                break;
              case "active":
                this.SubscriptionAlertColor = "success";
                this.SubscriptionAlertTitle = "Subscription Active";
                this.SubscriptionAlertText =
                  "The user is currently subscribed. End date: " +
                  response?.endDate;
                break;
              case "unpaid":
                this.SubscriptionAlertColor = "warning";
                this.SubscriptionAlertTitle = "Subscription Unpaid";
                this.SubscriptionAlertText = "The subscription is unpaid.";
                break;
              case "canceled":
                this.SubscriptionAlertColor = "danger";
                this.SubscriptionAlertTitle = "Subscription Canceled";
                this.SubscriptionAlertText = "The subscription is canceled.";
                break;
              case "incomplete":
                this.SubscriptionAlertColor = "danger";
                this.SubscriptionAlertTitle = "Subscription Incomplete";
                this.SubscriptionAlertText = "The subscription is incomplete.";
                break;
              case "trialing":
                this.SubscriptionAlertColor = "success";
                this.SubscriptionAlertTitle = "Subscription Active";
                this.SubscriptionAlertText =
                  "The subscription is currently in trial mode. End date: " +
                  response?.endDate;
                break;
              case "ended":
                this.SubscriptionAlertColor = "danger";
                this.SubscriptionAlertTitle = "Subscription Ended";
                this.SubscriptionAlertText = "The subscription has ended.";
                break;
              default:
                this.SubscriptionAlertColor = "danger";
                this.SubscriptionAlertTitle = "Inactive";
                this.SubscriptionAlertText =
                  "The user is not currently subscribed.";
                break;
            }
          })
          .catch((err) => {
            this.$swal.fire({
              title: "A problem occurred",
              text: "A problem occurred while trying to retrieve subscription status",
              icon: "error",
            });
          });

        /**
         * Get Training Status
         *
         * @type {Array}
         * @description Get spent time for each training that the user has taken
         */
        if (this.trainingstatus) {
          // get data from collection Demonstration
          firebase
            .firestore()
            .collection("trainingPages")
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                this.CourseNames.push(doc.data());
              });
              // order pages by 'order' field
              this.CourseNames.sort((a, b) => (a.order > b.order ? 1 : -1));
              // add all courses to trainingstatus
              for (const course of this.CourseNames) {
                this.trainingstatus.push({
                  id: course.id,
                  courseName: course.id,
                  timeSpent: 0,
                });
                // Now add deficits for these training pages if they require them
                if (course.id.indexOf("-") > -1) {
                }
              }
              firebase
                .firestore()
                .collection("users")
                .doc(this.$route.params.uid)
                .collection("Demonstration")
                .get()
                .then((trainings) => {
                  // foreach training check if it is in trainingstatus and add timeSpent
                  trainings.docs.forEach((training) => {
                    const data = training.data();
                    const found = this.trainingstatus.find((stat) => {
                      return stat.id.toLowerCase() === data.id.toLowerCase();
                    });
                    if (found) {
                      const timeSpent = data.secondsIn
                        ? data.secondsIn
                        : data.secounds;
                      found.timeSpent = timeSpent;
                      if (
                        found.id === "objective" ||
                        found.id === "prior" ||
                        found.id === "bibliography"
                      ) {
                        found.timeSpent = 1;
                      }
                    } else {
                      this.trainingstatus.push({
                        id: data.id,
                        courseName: data.id,
                        timeSpent: data.secondsIn
                          ? data.secondsIn
                          : data.secounds,
                      });
                    }
                  });

                  const FilteredTrainingStatus = [];

                  this.trainingstatus.forEach((training) => {
                    const found = FilteredTrainingStatus.find((stat) => {
                      return (
                        stat.id.toLowerCase() === training.id.toLowerCase()
                      );
                    });
                    let locatedCourseName = false;

                    this.CourseNames.forEach((course) => {
                      if (
                        course.id.toLowerCase() === training.id.toLowerCase()
                      ) {
                        training.courseName = course.title;
                        training.order = course.order;
                        locatedCourseName = true;
                      }
                    });
                    if (!locatedCourseName) {
                      training.courseName = training.courseName
                        .toLowerCase()
                        .replace(/-/g, " - Deficit ");
                      training.order = -1;
                    }
                    if (!found) {
                      FilteredTrainingStatus.push({
                        id: training.id,
                        order: training.order,
                        pageSection: training.id
                          .slice(0, 2)
                          .trim()
                          .replace(/-/g, ""),
                        courseName: training.courseName,
                        timeSpent:
                          isNaN(training.timeSpent) ||
                          training.timeSpent === undefined ||
                          training.timeSpent === null
                            ? 0
                            : parseInt(training.timeSpent),
                      });
                    } else {
                      let FoundTimeSpent = found.timeSpent;
                      let TrainingTimeSpent = training.timeSpent;
                      // Validate if the timeSpent is a number
                      FoundTimeSpent =
                        isNaN(FoundTimeSpent) ||
                        FoundTimeSpent === undefined ||
                        FoundTimeSpent === null
                          ? 0
                          : parseInt(FoundTimeSpent);
                      TrainingTimeSpent =
                        isNaN(TrainingTimeSpent) ||
                        TrainingTimeSpent === undefined ||
                        TrainingTimeSpent === null
                          ? 0
                          : parseInt(TrainingTimeSpent);
                      // set the new value
                      found.timeSpent = FoundTimeSpent + TrainingTimeSpent;
                    }
                  });
                  // itinerate FilteredTrainingStatus and convert the timeSpent to minutes and add format 00:00
                  FilteredTrainingStatus.forEach((training) => {
                    const minutes = Math.floor(training.timeSpent / 60);
                    const seconds = training.timeSpent - minutes * 60;
                    // add format 00:00
                    training.timeSpent = `${
                      minutes < 10 ? "0" + minutes : minutes
                    }:${seconds < 10 ? "0" + seconds : seconds}`;
                  });
                  this.trainingstatus = this.sortDemonstrationStats(
                    FilteredTrainingStatus
                  );
                  // run throught trainingstatus and check if id = "Objectives" then change timeSpent to "True"
                  this.trainingstatus.forEach((training) => {
                    if (
                      training.id === "objective" ||
                      training.id === "prior" ||
                      training.id === "bibliography"
                    ) {
                      if (training.timeSpent != "00:00") {
                        training.timeSpent = "True";
                      } else {
                        training.timeSpent = "False";
                      }
                    }
                  });
                });
            });
        }

        if (userData.orgsAdministered) {
          userData.orgsAdministered.forEach((org) => {
            org.get().then((orgSnapshot) => {
              const orgData = orgSnapshot.data();
              this.orgsAdministered.push({
                name: orgData.name,
                id: orgSnapshot.id,
              });
            });
          });
        }
      });
    },
    refreshTables() {
      // Removing my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },
    getExams() {
      this.exams = [];
      // resetting the exams before fetching them
      this.testsLoading = true;
      firebase
        .firestore()
        .collection("users")
        .doc(this.uid)
        .collection("Exams")
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            this.testsLoading = false;
            return;
          }
          snapshot.forEach((doc) => {
            const examData = doc.data();
            examData.examID = examData.Test?.id;
            examData.examAnswers = examData.Answers;
            examData.id = doc.id;
            examData.StartDate = moment
              .unix(examData.StartDate.seconds)
              .format("MM-DD-yy, h:mm:ss a");
            if (!examData.TestCompleted) {
              examData.TestCompleted = "N/A";
            } else {
              examData.TestCompleted = moment
                .unix(examData.TestCompleted.seconds)
                .format("MM-DD-yy, h:mm:ss a");
            }
            if (examData.Test) {
              examData.Test.get().then((theTest) => {
                const actualtest = theTest.data();
                examData.questionCount = actualtest.questions.length;
                examData.examName = actualtest.Name;
                this.exams.push(examData);
              });
            }
          });
          this.testsLoading = false;
        });
    },
    removeExam() {
      firebase
        .firestore()
        .collection("users")
        .doc(this.uid)
        .collection("Exams")
        .doc(this.rightClickData.id)
        .delete()
        .then(() => {
          this.getExams();
        });
    },
    closeModal(){
      this.addExamModalStatus = false;
    },
    openAddExamModal(){
      this.addExamModalStatus = true;
    },
    addExam() {
      this.$swal.fire({
        icon: 'info',
        title: 'Verifying...',
        html: 'We are checking if this user already have an active exam...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onOpen: async () => {
            this.$swal.showLoading();
            const examsSnapshot = await firebase
            .firestore()
            .collection("users")
            .doc(this.uid)
            .collection("Exams")
            .get()
            let activeExam = false;

            if(examsSnapshot.empty){
              this.selectedOptionsForExams = await this.fetchExams()
              this.$swal.close();
              this.addExamModalStatus = true;
            }

            for await (const doc of examsSnapshot.docs) {
              const examData = doc.data();
              if (!examData.hasOwnProperty("TestCompleted")) {
                activeExam = true;
              }
              if (activeExam) {
                this.$swal.fire({
                  title: "Active Exam Already Exists",
                  icon: "warning",
                });
                this.addExamModalStatus = false;
              } else {
                // Grab all exams and great select options
                this.selectedOptionsForExams = await this.fetchExams()
                this.$swal.close();
                this.addExamModalStatus = true;
              }
            };
      }})
    },
    async saveExamChanges(result){
      this.$swal.fire({
        icon: 'info',
        title: 'Updating...',
        html: 'We are adding the exam to the user...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        onOpen: async () => {
          this.$swal.showLoading();
          const Timestamp = firebase.firestore.Timestamp;
          // If exam added, go through add process
          const selectedOption = result.id;
          const TestRef = firebase
            .firestore()
            .collection("Test")
            .doc(selectedOption);
          await firebase
            .firestore()
            .collection("users")
            .doc(this.uid)
            .collection("Exams")
            .add({
              Answers: [],
              StartDate: Timestamp.now(),
              Test: TestRef,
          });
          this.addExamModalStatus = false;
          //swal success
          this.$swal.fire({
            title: "Exam Added",
            text: "The exam has been added successfully.",
            icon: "success",
          });
          this.getExams();
      }});
    },
    async fetchExams(){
      const availableTests = await firebase
      .firestore()
      .collection("Test")
      .where("Deleted", "==", false)
      .get()
   
      const selectOptions = [];
      availableTests.forEach((doc) => {
        const testData = doc.data();
        selectOptions.push({ id: doc.id, name: testData.Name });
      });
      selectOptions.sort((a, b) => a.name.localeCompare(b.name)); // Sort options based on name
      return selectOptions
    },
    mostlyCompleteExamCorrectly() {
      firebase
        .firestore()
        .collection("users")
        .doc(this.uid)
        .collection("Exams")
        .doc(this.rightClickData.id)
        .get()
        .then((snapshot) => {
          const userExamData = snapshot.data();
          userExamData.Test.get().then((examSnapshot) => {
            const examData = examSnapshot.data();
            const correctAnswers = examData.questions.map((question) => {
              let correctAnswer = 0;
              question.Answers.forEach((answer, index) => {
                if (answer.Correct) {
                  correctAnswer = index;
                }
              });
              return correctAnswer;
            });
            correctAnswers.pop();

            firebase
              .firestore()
              .collection("users")
              .doc(this.uid)
              .collection("Exams")
              .doc(this.rightClickData.id)
              .update({
                Answers: correctAnswers,
              })
              .then(() => {
                this.getExams();
              });
          });
        });
    },
    mostlyCompleteExamIncorrectly() {
      firebase
        .firestore()
        .collection("users")
        .doc(this.uid)
        .collection("Exams")
        .doc(this.rightClickData.id)
        .get()
        .then((snapshot) => {
          const userExamData = snapshot.data();
          userExamData.Test.get().then((examSnapshot) => {
            const examData = examSnapshot.data();
            const randomAnswers = examData.questions.map((question) => {
              return Math.floor(Math.random() * question.Answers.length);
            });
            randomAnswers.pop();
            firebase
              .firestore()
              .collection("users")
              .doc(this.uid)
              .collection("Exams")
              .doc(this.rightClickData.id)
              .update({
                Answers: randomAnswers,
              })
              .then(() => {
                this.getExams();
              });
          });
        });
    },
    ok() {
      this.showModal = false;
    },
    onsubmit() {
      return false;
    },
    resetPassword() {
      this.resettingPassword = true;
      const userId = this.uid;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "This will reset the password for this user",
          input: "text",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, reset it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const newPass = result.value;
            axios
              .post(`api/admin/adminResetUserPassword`, {
                uid: userId,
                password: newPass,
              })
              .then((result) => {
                this.$swal.fire(
                  "Password Reset!",
                  "Password reset to " + newPass,
                  "success"
                );
                this.resettingPassword = false;
              });
          } else {
            this.resettingPassword = false;
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    addTraining() {
      const availableTraining = [];
      this.loading = true;
      const levels = {};
      for (let i = 0; i < 15; i++) {
        levels[i] = true;
      }

      firebase
        .firestore()
        .collection("course")
        .where("Active", "==", true)
        .where("Deleted", "==", false)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((course) => {
            availableTraining.push(course.id);
          });
        })
        .then(() => {
          availableTraining.forEach((training) => {
            firebase
              .firestore()
              .collection("users")
              .doc(this.$route.params.uid)
              .collection("Trainings")
              .doc(training)
              .set(
                {
                  levels,
                  model: training,
                },
                { merge: true }
              );
          });
          firebase
            .firestore()
            .collection("users")
            .doc(this.$route.params.uid)
            .update({
              Trainings: availableTraining,
            });
          this.getUser();
        });
    },
    resetTraining() {
      this.$swal
        .fire({
          title: "Do you want to reset training for " + this.name + "?",
          showCancelButton: true,
          icon: "warning",
        })
        .then((result) => {
          if (result.isConfirmed) {
            firebase
              .firestore()
              .collection("users")
              .doc(this.$route.params.uid)
              .collection("Trainings")
              .get()
              .then((snapshot) => {
                snapshot.docs.forEach((training) => {
                  // doc.delete()
                  training.ref.delete();
                });
              });
            firebase
              .firestore()
              .collection("users")
              .doc(this.$route.params.uid)
              .update({
                Trainings: [],
              })
              .then(() => {
                this.$swal.fire({
                  icon: "success",
                  title: "Training has been reset",
                });
                this.getUser();
              });
          }
        });
    },
    removeTraining(row) {
      this.$swal
        .fire({
          title:
            "Are you sure you want to remove " + row.Name + " from training",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "DESTROY!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            firebase
              .firestore()
              .collection("users")
              .doc(this.$route.params.uid)
              .collection("Trainings")
              .doc(row.id)
              .delete();
            firebase
              .firestore()
              .collection("users")
              .doc(this.$route.params.uid)
              .update({
                Trainings: firebase.firestore.FieldValue.arrayRemove(row.id),
              })
              .then(() => {
                this.getUser();
                this.$swal.fire(
                  "Destroyed!",
                  "Training has been removed",
                  "success"
                );
              });
          }
        });
      //  firebase.firestore().collection('users').doc(this.$route.params.uid).collection('Trainings').get().then((trainings)=>{
      //   trainings.docs.forEach(async (training)=>{
      //     const trainingData = training.data();

      //     trainingData.course = await trainingData.course.get().then((snap)=>{
      //       const data = snap.data();
      //       data.id = snap.id;
      //       return data;
      //     });
      //     const found = trainingStats.find((stat)=>{
      //       return stat.course.id === trainingData.course.id;
      //     });
      //     if (!found) {
      //       trainingStats.push({
      //         course: trainingData.course,
      //         secondsIn: trainingData.secondsIn,
      //       });
      //     } else {
      //       found.secondsIn += trainingData.secondsIn;
      //     }
      //   });
    },
    async deleteUser() {
      const { value: confirmation } = await this.$swal.fire({
        title: "Are you sure you want to delete " + this.name + "?",
        input: "text",
        icon: "warning",
        html: 'Type \<em style="font-style: italic;">delete me</em>\ below',
        // inputLabel: 'Type \'<i>delete me</i>\' into the text field',
        showCancelButton: true,
        inputValidator: (value) => {
          if (value !== "delete me") {
            return "You need to input 'delete me' then confirm";
          }
        },
      });

      if (confirmation) {
        this.$swal.fire("Removing user...");
        this.loading = true;
        axios
          .post(`api/admin/removeUser`, { uid: this.uid })
          .then((result) => {
            this.$router.push({ name: "findusers" });
          })
          .catch((err) => {
            this.$swal.fire({
              icon: "error",
              title: err,
            });
          });
      }
    },
    //validate country, state and city, all of them have to be strings or null
    validateFields(field, name = "Field") {
      if (field != null && typeof field != "string") {
        console.error(field);
        throw new Error(name + " is not valid");
      }
    },
    async updateUserData() {
      try {
        this.validateFields(this.country?.name, "Country");
        this.validateFields(this.state?.abbreviation, "State");
        this.validateFields(this.city?.name, "City");
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: error.message,
        });
        return;
      }

      const userData = {
        email: this.email,
        name: this.name ? this.name : "",
        phoneNumber: this.phoneNumber ? this.phoneNumber : "",
        displayName: this.name ? this.name : "",
        ...(this.defaultDeveloper !== this.developer && {
          developer: this.developer,
        }),
        ...(this.defaultEmailIsVerified !== this.emailIsVerified && {
          emailIsVerified: this.emailIsVerified,
        }),
        ...(this.defaultFreeSubscription !== this.freeSubscription && {
          freeSubscription: this.freeSubscription,
        }),
        ...(this.defaultDemoAccess !== this.demoAccess && {
          demoAccess: this.demoAccess,
        }),
        ...(this.defaultIsDeactivated !== this.isDeactivated && {
          isDeactivated: this.isDeactivated,
        }),
        credentialCode: this.credential.value
          ? this.credential.value
          : this.credential,
        countryCode: this.country?.name ? this.country.name : this.country,
        stateCode: this.state?.abbreviation
          ? this.state.abbreviation
          : this.state,
        cityCode: this.city?.name ? this.city.name : this.city,
      };
      if (userData.stateCode == undefined) {
        userData.stateCode = "";
      }

      const userRef = firebase.firestore().collection("users").doc(this.uid);
      try {
        await userRef.update(userData);
        this.defaultDemoAccess = this.demoAccess;
        this.defaultIsDeactivated = this.isDeactivated;
        this.defaultDeveloper = this.developer;
        this.defaultEmailIsVerified = this.emailIsVerified;
        this.defaultFreeSubscription = this.freeSubscription;
        this.$swal.fire({
          icon: "success",
          title: `Successfully updated ${this.name ? this.name : "user"}`,
          html: "The user data has been updated",
          timer: 1500,
        });
        this.loading = false;
      } catch (e) {
        this.$swal.fire({
          icon: "error",
          title: e,
        });
        this.loading = false;
      }
    },
    async updateUserEmail(email, id) {
      await axios
        .post(`api/admin/updateUserEmail`, { email: email, uid: id })
        .then((result) => {
          return true;
        })
        .catch((err) => {
          this.$swal.fire({
            icon: "error",
            title: err,
          });
        });
    },
    async updateUser() {
      // check if firstEmail is different from email
      if (this.firstEmail !== this.email) {
        this.$swal
          .fire({
            title: "Are you sure you want to change the email?",
            text: "The user will need to login again with the new email",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, change it!",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              // show loading
              this.$swal.fire({
                title: "Updating data...",
                allowOutsideClick: false,
                // hide "ok" button
                showConfirmButton: false,
                onBeforeOpen: () => {
                  this.$swal.showLoading();
                },
              });
              await this.updateUserEmail(this.email, this.uid);
              await this.updateUserData();
            }
          });
      } else {
        // show loading
        this.$swal.fire({
          title: "Updating data...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
        await this.updateUserData();
      }
    },
    redirectEvaluation() {
      this.$router.push({
        name: "evaluation-statistics-unique",
        params: { userID: this.uid },
      });
    },
    async printCert(data, type) {
      //check if the user has completed the evaluation
      if (!data.evalCompleted) {
        let response = await this.$swal
          .fire({
            icon: "info",
            title: "Are you sure?",
            html: '<b>The user has <text style="color: red;">not</text> completed the evaluation.</b> Please have the user complete the evaluation before printing the certificate.',
            showCancelButton: true,
            confirmButtonText: "Print Certificate",
            cancelButtonText: "Cancel Print",
            focusCancel: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              return true;
            }
            return false;
          });
        //Just in case that in the future we want to do something if the user cancels the print
        if (response === false) {
          return;
        }
      }
      this.certLoading = true;
      axios
        .post(`api/admin/encryptJSON`, {
          userID: this.uid,
          examID: data.id,
        })
        .then((result) => {
          this.certLoading = false;
          window.open(
            `${window.firebaseURL}api/users/getEncryptedCertificate/${result.data.message}?certType=${type}&mandatory=true`
          );
        })
        .catch((e) => {
          console.error(e);
          this.$swal.fire({
            icon: "error",
            title: "Unable to get certificate",
          });
        });
    },
    async emailCert(data) {
      // sweet alert with input text and select
      const { value: formValues } = await this.$swal.fire({
        title: "Email Certificate",
        html: `
        <input id="swal-input1"  value="${this.email}" class="swal2-input" placeholder="Email Address">
        <select id="swal-input2" class="swal2-input">
          <option value="0">CE</option>
          <option value="1">Completion</option>
          <option value="2">Both Certificates</option>
        </select>
        `,
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value,
          ];
        },
      });

      if (formValues) {
        // this.emailing = true;
        axios
          .post(`api/admin/encryptJSON`, {
            userID: this.uid,
            examID: data.id,
          })
          .then(async (result) => {
            const certificateTemplate = await firebase
              .firestore()
              .collection("emailTemplates")
              .doc("certificate")
              .get()
              .then((snapshot) => {
                const data = snapshot.data();
                return data;
              });

            let attachments = [];
            switch (formValues[1]) {
              case "0":
                attachments = [
                  {
                    filename: "NIHSSCertificate.pdf",
                    path:
                      window.firebaseURL +
                      "api/users/getEncryptedCertificate/" +
                      result.data.message +
                      "?certType=CE",
                  },
                ];
                break;
              case "1":
                attachments = [
                  {
                    filename: "NIHSSCertificate.pdf",
                    path:
                      window.firebaseURL +
                      "api/users/getEncryptedCertificate/" +
                      result.data.message +
                      "?certType=completion",
                  },
                ];
                break;
              case "2":
                attachments = [
                  {
                    filename: "NIHSSCertificate_CE.pdf",
                    path:
                      window.firebaseURL +
                      "api/users/getEncryptedCertificate/" +
                      result.data.message +
                      "?certType=CE",
                  },
                  {
                    filename: "NIHSSCertificate.pdf",
                    path:
                      window.firebaseURL +
                      "api/users/getEncryptedCertificate/" +
                      result.data.message +
                      "?certType=completion",
                  },
                ];
                break;
            }
            firebase
              .firestore()
              .collection("email")
              .add({
                to: formValues[0],
                message: {
                  subject: certificateTemplate.subject,
                  html: certificateTemplate.html,
                  attachments: attachments,
                },
              })
              .then(() => {
                this.emailing = false;
                this.$swal.fire({
                  icon: "success",
                  title: "Certificate Sent!",
                  timer: 1500,
                });
              });
          })
          .catch((e) => {
            console.error(e);
            this.emailing = false;
            this.$swal.fire({
              icon: "error",
              title: "Unable to send certificate",
            });
          });
      }
    },
    sortDemonstrationStats(filteredArray) {
      filteredArray = filteredArray.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      );

      let unsortedPages = [];

      for (let i = 0; i < filteredArray.length; i++) {
        if (filteredArray[i].order == -1) {
          let pageOrder = filteredArray[i].courseName.slice(-1);
          if (isNaN(pageOrder)) {
            if (filteredArray[i].courseName.slice(-10) === "Telestroke") {
              pageOrder = 5;
            } else {
              pageOrder = 6;
            }
          }
          filteredArray[i].pageOrder = pageOrder;
          unsortedPages.push(filteredArray[i]);
          filteredArray.splice(i, 1);
          i--;
        }
      }

      unsortedPages = unsortedPages.sort(
        (a, b) =>
          a.pageSection.localeCompare(b.pageSection) ||
          a.pageOrder - b.pageOrder
      );
      unsortedPages.reverse();
      // Now that we have all pages that were not sorted properly, lets add them back into the filtered array at the correct index
      // To determine index, find the filtered page that does not have a page section that matches, and add it behind
      for (let i = 0; i < unsortedPages.length; i++) {
        filteredArray.find((page, index) => {
          if (
            page.pageSection === unsortedPages[i].pageSection &&
            !page.pageOrder
          ) {
            filteredArray.splice(index + 1, 0, unsortedPages[i]);
            unsortedPages[i].sorted = true;
          }
        });
      }

      // If we missed one during sorting for some weird reason, add it to end of array
      for (let i = 0; i < unsortedPages.length; i++) {
        if (!unsortedPages[i].sorted) {
          filteredArray.splice(filteredArray.length, 0, unsortedPages[i]);
        }
      }

      return filteredArray;
    },
  },
};
</script>

<style  lang="scss">
  .custom-dropdown-size .va-dropdown__content {
    height: 35vh !important;
  }

  .hideBottomMargin {
    margin-bottom: 0 !important;
  }

  .toggleBody {
    align-items: center;
    margin-bottom: 1 !important;
  }

  .imgContainer {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
  }

  .bigAvatar {
    display: flex;
    width: 100%;
    max-width: 250px;
    overflow: hidden;
  }

  .centerEverything {
    display: flex;
    text-align: center;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
  }

  .modal-container {
    height: 300px;
    overflow-y: auto;
  }

  .custom-qa-modal > div {
    max-width: 1300px !important;
  }

  .custom-qa-modal > div .va-modal__inner {
    max-width: 1300px !important;
  }

</style>

