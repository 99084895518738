class ExamUtils {

    /**
     * @description Get the exam date and time and return the formatted date
     * @param {*} date 
     * @returns 
     */
    format(date) {
        try {
          if(date === "N/A" || !date) return date;

          //format date to MM/DD/YYYY, HH:MM:SS AM/PM
          let dateObj = new Date(date);
          let month = dateObj.getUTCMonth() + 1; //months from 1-12
          let day = dateObj.getUTCDate();
          let year = dateObj.getUTCFullYear();
          let hours = dateObj.getHours();
          let minutes = dateObj.getMinutes();
          let ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? "0" + minutes : minutes;
          let strTime = hours + ":" + minutes + " " + ampm;
          return month + "/" + day + "/" + year + ", " + strTime;
        } catch (error) { 
          console.log(error);
          return date
        }
      }
}

export default ExamUtils;