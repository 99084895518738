<template>
  <div>
    <va-modal
      v-model="active"
      size="large"
      class="flex xs12"
      :hide-default-actions="true"
    >
      <div class="mx-0">
        <div style="max-height: 400px; overflow-y: auto">
          <div>
            <section>
              <h1> Select Exam </h1>
              <p>Select an exam to add to the user </p>
              <br/>
              <div>
                <va-select
                  class="custom-select"
                  v-model="selected"
                  key-by="id"
                  text-by="name"
                  placeholder="Select an Exam"
                  :options="options"
                  searchable
                />
              </div>
            </section>
          </div>
        </div>
      </div>

      <div :slot="'actions'">
        <template>
          <va-button @click="saveExamChanges()">
            Save
          </va-button>
          <va-button @click="emitClose" color="#cccccc">
            Close
          </va-button>
        </template>
      </div>
    </va-modal>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      selected: "",
    };
  },
  created() {
    console.log('AddExamModal.vue mounted');
  },
  methods: {
    saveExamChanges() {
    //check if it is empty
    if (this.selected === "") {
        this.$swal.fire({
            title: 'Error!',
            text: 'Please select an option',
            icon: 'error',
            confirmButtonText: 'OK'
        });
        return;
    }
      this.$emit('save', this.selected);
    },
    emitClose() {
      this.$emit('close');
    },
  },
};
</script>
