<template>
  <div>
    <va-card title="Exam History">
      <template slot="actions">
        <div
          style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px"
        >
          <va-checkbox :label="'Show Passed Only'" v-model="showPassedOnly" />
          <va-button @click="addExam" v-if="!loading"> Add Exam </va-button>
          <va-button v-else>
            <semipolar-spinner :color="'white'" />
          </va-button>
        </div>
      </template>

      <va-data-table
        :fields="fields"
        :data="filteredExams"
        :per-page="10"
        :loading="testsLoading"
        hoverable
      >
        <!-- <template slot="model" slot-scope="props">
          {{ getModelData(props.rowData) }}
        </template> -->
        <template slot="testCompleted" slot-scope="props">
          <div
            @contextmenu.prevent="preventContextMenu($event, props)"
          >
            <div
              v-if="props.rowData.TestCompleted !== ''"
              :title="'Started: ' + props.rowData.StartDate"
            >
              {{ formatCompletedDate(props.rowData.TestCompleted) }}
            </div>
            <div v-else>
              <div class="d-flex justify--center">
                <div>
                  <va-progress-circle
                    :value="
                      (props.rowData.Answers.length /
                        props.rowData.questionCount) *
                      100
                    "
                  >
                    {{
                      parseInt(
                        (props.rowData.Answers.length /
                          props.rowData.questionCount) *
                          100
                      )
                    }}%
                  </va-progress-circle>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template slot="score" slot-scope="props">
          <div
            @contextmenu.prevent="preventContextMenu($event, props)"
          >
              <span>
                <a
                  href="#"
                  @click="
                    showScoreData(
                      props.rowData.examID,
                      props.rowData.examAnswers
                    )
                  "
                >
                  {{ props.rowData.Score ?  props.rowData.Score + "%" : "No Answers" }}</a>
              </span>
          </div>
        </template>

        <template slot="passed" slot-scope="props">
          <div v-if="props.rowData.TestCompleted !== ''">
            <div v-if="props.rowData.Passed">
              <div class="row">
                <div>
                  <va-button
                    :icon="
                      certLoading ? 'fa fa-spinner fa-spin' : 'fa fa-file-text'
                    "
                    :disabled="!props.rowData.evalCompleted"
                    color="info"
                    @click="redirectEvaluation(props.rowData)"
                  >
                    Evaluation
                  </va-button>
                  <va-button
                    :icon="
                      certLoading ? 'fa fa-spinner fa-spin' : 'fa fa-print'
                    "
                    :disabled="certLoading"
                    color="info"
                    @click="printCert(props.rowData, 'CE')"
                  >
                    CE
                  </va-button>
                  <va-button
                    :icon="
                      certLoading ? 'fa fa-spinner fa-spin' : 'fa fa-print'
                    "
                    :disabled="certLoading"
                    color="info"
                    @click="printCert(props.rowData, 'Completion')"
                  >
                    Completion
                  </va-button>
                  <va-button
                    :icon="
                      emailing ? 'fa fa-spinner fa-spin' : 'fa fa-envelope'
                    "
                    :disabled="!props.rowData.evalCompleted"
                    @click="emailCert(props.rowData)"
                  >
                    Email
                  </va-button>
                </div>
              </div>
            </div>
            <div v-else>
              <a
                href="#"
                @click="
                  showScoreData(props.rowData.examID, props.rowData.examAnswers)
                "
              >
              {{ props.rowData.Score ?  props.rowData.Score + "%" : "No Answers" }}
              </a>
            </div>
          </div>
        </template>
      </va-data-table>
    </va-card>
  </div>
</template>

<script>
import { SemipolarSpinner } from "epic-spinners";
import NihssTickets from "../tickets/nihss-tickets.vue";
import ExamUtils from "../../class/utils/ExamUtils";
import AddExamModal from "../users/regular/AddExamModal.vue";

export default {
  components: {
    SemipolarSpinner,
    NihssTickets,
    AddExamModal,
  },
  props: {
    showPassedOnly: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    testsLoading: {
      type: Boolean,
      required: true,
    },
    certLoading: {
      type: Boolean,
      required: true,
    },
    exams: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    emailing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    filteredExams() {
      if (!this.showPassedOnly) {
        return this.exams;
      } else {
        return this.exams.filter((exam) => {
          return exam.Passed;
        });
      }
    },
  },
  methods: {
    formatCompletedDate(date) {
      try {
        let eUtils = new ExamUtils();
        return eUtils.format(date);
      } catch (error) { 
        console.log(error);
        return date
      }
    },
    addExam(e){
      this.$emit('addExam');
    },
    showScoreData(examID, examAnswers) {
      this.$emit("showScoreData", examID, examAnswers);
    },
    redirectEvaluation(exam) {
      this.$emit("redirectEvaluation", exam);
    },
    printCert(exam, type) {
      this.$emit("printCert", exam, type);
    },
    emailCert(exam) {
      this.$emit("emailCert", exam);
    },
    preventContextMenu(e, props) {
      this.$emit("contextAction", e, props);
    },

  }
};
</script>
